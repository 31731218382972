import { useEffect } from 'react';

import { useRouter } from 'next/router';

import useActiveAuth from 'hooks/useActiveAuth';

import debouncing from 'utils/debouncing';
import { addEventToDataLayer } from 'utils/forGTM/dataLayerHandlerForGTM';
import getPageNameForGTM from 'utils/forGTM/getPageNameForGTM';

const VIEW_PAGE_EVENT = 'viewPage';

// ! TODO 데이터 보내주는 이벤트는 별도로 구분해도 되는데 무조건 데이터가 먼저 도착해야함.
export const hasCustomAttributesPage = (route: string) => {
  const hasCustomAttributesPageList = [
    '/channel/[handle]',
    '/control/[matchId]',
    '/control/app/[matchId]',
    '/event/[eventId]',
    '/event',
    '/match/[matchId]',
  ];

  return hasCustomAttributesPageList.includes(route);
};

export const usePageViewEventForGTM = ({
  enabled = true,
  customAttributes,
  deps,
}: {
  enabled?: boolean;
  customAttributes?: Record<string, string | boolean | number | undefined>;
  deps?: unknown;
}) => {
  const { isProfileLoadProcessComplete } = useActiveAuth();
  const { route } = useRouter();

  const defaultEnabled = isProfileLoadProcessComplete;

  useEffect(() => {
    if (defaultEnabled && enabled !== false)
      debouncing(() => {
        addEventToDataLayer({
          event: VIEW_PAGE_EVENT,
          pageType: getPageNameForGTM(route),
          ...customAttributes,
        });
      }, 100);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabled, defaultEnabled, deps]);
};
