import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

const CheckedBoxIcon = ({ htmlColor = '#6E717E', ...props }: SvgIconProps) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M19 3H5C3.89 3 3 3.9 3 5V19C3 20.1 3.89 21 5 21H19C20.11 21 21 20.1 21 19V5C21 3.9 20.11 3 19 3Z"
        fill="#37C556"
      />
      <path
        d="M7.3999 10.98L10.8897 14.4L16.3999 9"
        stroke="white"
        strokeWidth="2"
      />
    </svg>
  </SvgIcon>
);

export default CheckedBoxIcon;
