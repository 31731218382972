import { useEffect } from 'react';

import { useRouter } from 'next/router';

import { Box } from '@mui/material';
import { getCookie } from 'cookies-next';

import type { AdIdType } from 'types/auth';

const CustomAdCard = ({
  adId,
  width,
  height,
  defaultImageURL,
  onClick,
}: {
  adId: AdIdType;
  width: string;
  height: string;
  defaultImageURL?: string;
  onClick?: () => void;
}) => {
  const { push } = useRouter();

  useEffect(() => {
    if (
      getCookie('localConsent') === undefined ||
      getCookie('localConsent') === 'false'
    ) {
      (window.adsbygoogle =
        window.adsbygoogle || []).requestNonPersonalizedAds = 1;
    }

    if (process.env.NEXT_PUBLIC_STUDIO_NODE_ENV !== 'local')
      (window.adsbygoogle = window.adsbygoogle || []).push({});
  }, []);

  const adIdList = {
    검색_검색카운트하단_배너_공통: 3240861458,
    경기상세_스코어하단_배너_공통: 4909780357,
    메인_첫번째컨텐츠리스트하단_배너_공통: 4917523757,
    메인_전체경기상단_배너_공통: 9273495379,
    모든_검색배너하단_배너_모바일: 1146262722,
    모든_검색배너하단_배너_웹: 7903242765,
    모든_경기리스트_배너_공통: 8962789595,
    모든_더보기팝업중간_배너_모바일: 1152920013,
    모든_생성하기버튼상단_카드_모바일: 1823251279,
    모든_우측사이드_카드_웹: 8476478517,
    모든_이벤트리스트_배너_공통: 9026121356,
    모든_이벤트리스트_카드_공통: 4760369444,
    모든_푸터상단_배너_공통: 7802922998,
    설정_탭하단_배너_공통: 7030521512,
    실시간자막_기록상단_배너_공통: 7710088495,
    실시간자막_페이지상단_배너_공통: 3032476888,
    이벤트상세_상세내용하단_배너_공통: 7527045656,
    이벤트상세_우측리모콘하단_배너_웹: 7611351304,
    이벤트상세_페이지상단_배너_공통: 6726263591,
    지난자막_페이지상단_배너_공통: 6058596485,
    채널_페이지상단_배너_공통: 5586403944,
    컨트롤_위젯미리보기하단_배너_공통: 5900267089,
    컨트롤_컨트롤러내부_배너_공통: 3663032276,
    컨트롤_컨트롤러하단_배너_공통: 4246821362,
    컨트롤_테마탭중간_배너_공통: 6681413013,
  };

  return (
    <Box
      className="adsbygoogle gtm-google-ads-ad-btn"
      sx={{
        position: 'relative',
        borderRadius: 2,
        overflow: 'hidden',
        ins: {
          width: `${width} !important`,
          height: `${height} !important`,

          '&.adsbygoogle': {
            display: 'block',

            '.default-ad-image': {
              display: defaultImageURL ? 'block !important' : 'none !important',
              cursor: 'pointer',
            },
          },

          '&.adsbygoogle[data-ad-status="filled"]': {
            '.default-ad-image': { display: 'none !important' },
          },
        },
      }}
    >
      <ins
        className="adsbygoogle"
        data-ad-client="ca-pub-4449874113484547"
        data-ad-slot={adIdList[adId]}
        style={{
          display: 'block',
          textAlign: 'center',
          width: `${width} !important`,
          height: `${height} !important`,
        }}
      >
        <Box
          onClick={() => {
            if (onClick) onClick();
            else push('/membership');
          }}
          className="default-ad-image"
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            backgroundImage: `url(${defaultImageURL})`,
            width: width,
            height: height,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            zIndex: 10,
          }}
        />
      </ins>
    </Box>
  );
};

export default CustomAdCard;
