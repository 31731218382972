import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

const CalendarPlusIcon = ({ ...props }: SvgIconProps) => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="18"
    viewBox="0 0 19 18"
    {...props}
  >
    <path
      d="M16.25 6H2.75M12.5 1.5V3.75M6.5 1.5V3.75M9.5 13.5V9M7.25 11.25H11.75M6.35 16.5H12.65C13.9101 16.5 14.5402 16.5 15.0215 16.2548C15.4448 16.039 15.789 15.6948 16.0048 15.2715C16.25 14.7902 16.25 14.1601 16.25 12.9V6.6C16.25 5.33988 16.25 4.70982 16.0048 4.22852C15.789 3.80516 15.4448 3.46095 15.0215 3.24524C14.5402 3 13.9101 3 12.65 3H6.35C5.08988 3 4.45982 3 3.97852 3.24524C3.55516 3.46095 3.21095 3.80516 2.99524 4.22852C2.75 4.70982 2.75 5.33988 2.75 6.6V12.9C2.75 14.1601 2.75 14.7902 2.99524 15.2715C3.21095 15.6948 3.55516 16.039 3.97852 16.2548C4.45982 16.5 5.08988 16.5 6.35 16.5Z"
      stroke="#78788A"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);

export default CalendarPlusIcon;
