import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

const CautionIcon = ({ htmlColor = '#6E717E', ...props }: SvgIconProps) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <circle cx="12" cy="12" r="10" fill="#FFB200" />
    <path
      d="M11.9912 5C12.7494 5 13.3586 5.62469 13.3397 6.38264L13.1695 13.1884C13.1535 13.8277 12.6307 14.3377 11.9912 14.3377C11.3517 14.3377 10.8289 13.8277 10.8129 13.1884L10.6427 6.38264C10.6237 5.62469 11.233 5 11.9912 5ZM12.0092 18.5C11.1649 18.5 10.4824 17.8001 10.5003 16.9529C10.4824 16.1057 11.1649 15.4243 12.0092 15.4243C12.7995 15.4243 13.5 16.1057 13.5 16.9529C13.5 17.8001 12.7995 18.5 12.0092 18.5Z"
      fill="white"
    />
  </SvgIcon>
);

export default CautionIcon;
