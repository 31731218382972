import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

const Rollback = (props: SvgIconProps) => (
  <SvgIcon width="19" height="21" viewBox="0 0 19 21" {...props}>
    <path d="M7.2826 20.6421C8.49001 20.9656 9.68221 21.0394 10.8592 20.8633C12.037 20.6868 13.1208 20.3126 14.1106 19.7406C15.1008 19.1694 15.9668 18.4179 16.7086 17.4862C17.2489 16.8071 17.6782 16.0547 17.9965 15.2288C18.1951 14.7135 17.8728 14.1622 17.3394 14.0193C16.8059 13.8764 16.2648 14.197 16.0549 14.7078C15.4711 16.128 14.493 17.234 13.1206 18.0259C11.4573 18.9868 9.68379 19.2149 7.80024 18.7102C5.91668 18.2055 4.49487 17.1213 3.53479 15.4574C2.57389 13.794 2.34579 12.0206 2.85049 10.137C3.35519 8.25346 4.43955 6.83132 6.10357 5.8706C7.76677 4.91035 9.54015 4.68257 11.4237 5.18727L11.5686 5.22609L10.5277 5.82706C10.0517 6.10186 9.88376 6.70739 10.1502 7.18812C10.4233 7.68091 11.0474 7.85402 11.5353 7.57232L14.6802 5.75662C15.1585 5.48048 15.3224 4.86889 15.0462 4.39059L13.2305 1.24571C12.9488 0.757784 12.3218 0.595647 11.8389 0.885869C11.3678 1.16898 11.2105 1.77737 11.4853 2.25334L12.0862 3.29424L11.9413 3.25542C10.7339 2.93189 9.54132 2.85838 8.36351 3.03489C7.18651 3.21093 6.10289 3.58453 5.11265 4.1557C4.12288 4.72768 3.25721 5.47924 2.51561 6.41036C1.77449 7.3423 1.24216 8.41197 0.918639 9.61938C0.595115 10.8268 0.521371 12.019 0.697406 13.196C0.873913 14.3738 1.24784 15.4575 1.81918 16.4471C2.39099 17.4375 3.14246 18.3035 4.07358 19.0451C5.00552 19.7862 6.07519 20.3186 7.2826 20.6421Z" />
  </SvgIcon>
);

export default Rollback;
