import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

const Translate = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path
      d="M13.35 22C12.95 22 12.6627 21.871 12.488 21.613C12.3127 21.3543 12.2917 21.0333 12.425 20.65L16.075 10.975C16.175 10.7083 16.3627 10.479 16.638 10.287C16.9127 10.0957 17.2 10 17.5 10C17.7833 10 18.0667 10.0957 18.35 10.287C18.6333 10.479 18.825 10.7083 18.925 10.975L22.575 20.65C22.7083 21.0333 22.6877 21.3543 22.513 21.613C22.3377 21.871 22.0417 22 21.625 22C21.4417 22 21.275 21.9417 21.125 21.825C20.975 21.7083 20.8667 21.5667 20.8 21.4L19.95 18.95H15.1L14.225 21.4C14.1583 21.5667 14.0417 21.7083 13.875 21.825C13.7083 21.9417 13.5333 22 13.35 22ZM15.7 17.2H19.3L17.55 12.25H17.45L15.7 17.2ZM7.15 8.55C7.41667 9.03333 7.7 9.479 8 9.887C8.3 10.2957 8.65 10.7167 9.05 11.15C9.78333 10.35 10.3917 9.52933 10.875 8.688C11.3583 7.846 11.7667 6.95 12.1 6H2C1.71667 6 1.47933 5.90433 1.288 5.713C1.096 5.521 1 5.28333 1 5C1 4.71667 1.096 4.479 1.288 4.287C1.47933 4.09567 1.71667 4 2 4H8V3C8 2.71667 8.096 2.479 8.288 2.287C8.47933 2.09567 8.71667 2 9 2C9.28333 2 9.521 2.09567 9.713 2.287C9.90433 2.479 10 2.71667 10 3V4H16C16.2833 4 16.5207 4.09567 16.712 4.287C16.904 4.479 17 4.71667 17 5C17 5.28333 16.904 5.521 16.712 5.713C16.5207 5.90433 16.2833 6 16 6H14.1C13.75 7.18333 13.275 8.33333 12.675 9.45C12.075 10.5667 11.3333 11.6167 10.45 12.6L12.85 15.05L12.1 17.1L9 14L4.7 18.3C4.51667 18.4833 4.28333 18.575 4 18.575C3.71667 18.575 3.48333 18.4833 3.3 18.3C3.11667 18.1167 3.025 17.8833 3.025 17.6C3.025 17.3167 3.11667 17.0833 3.3 16.9L7.65 12.55C7.2 12.0333 6.78333 11.5123 6.4 10.987C6.01667 10.4623 5.675 9.90833 5.375 9.325C5.19167 8.975 5.18333 8.66667 5.35 8.4C5.51667 8.13333 5.80833 8 6.225 8C6.39167 8 6.56667 8.054 6.75 8.162C6.93333 8.27067 7.06667 8.4 7.15 8.55Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default Translate;
