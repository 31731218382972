const convertListToCamelCase = (stringList: string[]) => {
  return stringList
    .map((word, index) => {
      if (index === 0) return word.toLowerCase();
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join('');
};

/**
 * @description
 * 페이지 경로를 받아서 페이지 이름을 반환하는 함수
 * 예시 : '/channel/[handle]' -> 'channelDetail'
 */
const getPageNameForGTM = (route: string) => {
  if (route === '/') return 'main';
  if (route === '/event') return 'eventMain';

  const routeWordList = route.slice(1).split('/');

  return convertListToCamelCase(
    routeWordList.map((word) => {
      const isDynamicRoute = word.includes('[') && word.includes(']');
      if (isDynamicRoute) return 'detail';
      else return word;
    }),
  );
};

export default getPageNameForGTM;
