import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

const LiveIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.42128 23C7.85734 23 8.23717 22.8371 8.70475 22.5687L20.8415 15.5473C21.7278 15.0242 22.0914 14.6316 22.0914 13.9948C22.0914 13.3582 21.7278 12.9656 20.8415 12.4527L8.70475 5.42288C8.23717 5.15263 7.85734 5 7.42128 5C6.58499 5 6 5.63422 6 6.65074V21.3408C6 22.3574 6.58499 23 7.42128 23ZM8.16713 20.4934C8.10719 20.4934 8.06784 20.4522 8.06784 20.3735V7.61431C8.06784 7.53563 8.10719 7.49443 8.16713 7.49443C8.20462 7.49443 8.24396 7.51503 8.28701 7.54408L19.2618 13.8871C19.3115 13.9162 19.3471 13.9452 19.3471 13.9948C19.3471 14.0445 19.3115 14.082 19.2618 14.1044L8.28701 20.4522C8.24396 20.4728 8.20462 20.4934 8.16713 20.4934Z"
      fill="#A3A3A3"
    />
  </SvgIcon>
);

export default LiveIcon;
