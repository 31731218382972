import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

const EventIcon = ({ ...props }: SvgIconProps) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      {...props}
    >
      <path d="M15.8333 4.26487H14.1667V3.41173C14.1667 2.94251 13.7917 2.55859 13.3333 2.55859H6.66667C6.20833 2.55859 5.83333 2.94251 5.83333 3.41173V4.26487H4.16667C3.25 4.26487 2.5 5.03269 2.5 5.97115V6.82429C2.5 8.99979 4.1 10.7743 6.15833 11.0388C6.68333 12.3185 7.80833 13.2825 9.16667 13.5641V16.2088H6.66667C6.20833 16.2088 5.83333 16.5927 5.83333 17.0619C5.83333 17.5312 6.20833 17.9151 6.66667 17.9151H13.3333C13.7917 17.9151 14.1667 17.5312 14.1667 17.0619C14.1667 16.5927 13.7917 16.2088 13.3333 16.2088H10.8333V13.5641C12.1917 13.2825 13.3167 12.3185 13.8417 11.0388C15.9 10.7743 17.5 8.99979 17.5 6.82429V5.97115C17.5 5.03269 16.75 4.26487 15.8333 4.26487ZM5.83333 9.23014C4.86667 8.87182 4.16667 7.93337 4.16667 6.82429V5.97115H5.83333V9.23014ZM15.8333 6.82429C15.8333 7.93337 15.1333 8.87182 14.1667 9.23014V5.97115H15.8333V6.82429Z" />
    </SvgIcon>
  );
};

export default EventIcon;
