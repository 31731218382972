import { captureMessage } from '@sentry/nextjs';
import { runTransaction } from 'firebase/database';

import type { BaseballUnionLogType } from 'types/log';
import type {
  BaseballStatsUnion,
  MatchPlayer,
  PositionUnionType,
} from 'types/matchData';
import type {
  BaseBall,
  MatchData,
  ScoreboardData,
  TeamType,
} from 'types/scoreboardData';

import {
  getRef,
  makeInningEndLog,
  makeInningStartLog,
  makeTeamScoreLog,
} from './util';

export const updateIsTop = async (matchId: string, isTop: boolean) => {
  const baseBallRef = getRef(`scoreboard/${matchId}/baseBall`);
  try {
    await runTransaction(baseBallRef, (baseBallData: BaseBall) => {
      if (!baseBallData) throw new Error('baseBallData is null');
      baseBallData.isTop = isTop;
      baseBallData.out = '0';
      baseBallData.ball = '0';
      baseBallData.strike = '0';
      baseBallData.base1 = false;
      baseBallData.base2 = false;
      baseBallData.base3 = false;
      return baseBallData;
    });
  } catch (error) {
    captureMessage(`updateIsTop 에러 : ${error}`);
    throw error;
  }
};

export const updateBase = async (
  matchId: string,
  baseNum: number,
  baseValue: boolean,
) => {
  const baseBallRef = getRef(`scoreboard/${matchId}/baseBall`);
  try {
    await runTransaction(baseBallRef, (baseBallData: BaseBall) => {
      if (!baseBallData) throw new Error('baseBallData is null');
      baseBallData[`base${baseNum}`] = baseValue;
      return baseBallData;
    });
  } catch (error) {
    captureMessage(`updateBase 에러 : ${error}`);
    throw error;
  }
};

export const updateBallCount = async (matchId: string, ball: string) => {
  const baseBallRef = getRef(`scoreboard/${matchId}/baseBall`);
  try {
    await runTransaction(baseBallRef, (baseBallData: BaseBall) => {
      if (!baseBallData) throw new Error('baseBallData is null');
      baseBallData.ball = ball;
      return baseBallData;
    });
  } catch (error) {
    captureMessage(`updateBallCount 에러 : ${error}`);
    throw error;
  }
};

export const updateStrikeCount = async (matchId: string, strike: string) => {
  const baseBallRef = getRef(`scoreboard/${matchId}/baseBall`);
  try {
    await runTransaction(baseBallRef, (baseBallData: BaseBall) => {
      if (!baseBallData) throw new Error('baseBallData is null');
      baseBallData.strike = strike;
      return baseBallData;
    });
  } catch (error) {
    captureMessage(`updateStrikeCount 에러 : ${error}`);
    throw error;
  }
};

export const updateOutCount = async (
  matchId: string,
  out: string,
  strike?: string,
  ball?: string,
) => {
  const baseBallRef = getRef(`scoreboard/${matchId}/baseBall`);
  try {
    await runTransaction(baseBallRef, (baseBallData: BaseBall) => {
      if (!baseBallData) throw new Error('baseBallData is null');
      if (strike && ball) {
        baseBallData.out = out;
        baseBallData.strike = strike;
        baseBallData.ball = ball;
      } else {
        baseBallData.out = out;
      }
      return baseBallData;
    });
  } catch (error) {
    captureMessage(`updateOutCount 에러 : ${error}`);
    throw error;
  }
};

export const updateBaseBallInning = async (matchId: string, inning: string) => {
  const numInning = Number(inning);
  if (numInning <= 0 || numInning > 15) return;
  let prevInning = 0;
  const rootRef = getRef(`scoreboard/${matchId}`);
  try {
    const result = await runTransaction(rootRef, (rootData: ScoreboardData) => {
      if (rootData.baseBall) {
        prevInning = Number(rootData.baseBall.inning);
        rootData.baseBall.inning = inning;
        rootData.baseBall.isTop = true;
        rootData.baseBall.out = '0';
        rootData.baseBall.ball = '0';
        rootData.baseBall.strike = '0';
        rootData.baseBall.base1 = false;
        rootData.baseBall.base2 = false;
        rootData.baseBall.base3 = false;
        rootData.baseBall.homeTeamInningScore[numInning] =
          rootData.baseBall.homeTeamInningScore[numInning] ?? 0;
        rootData.baseBall.awayTeamInningScore[numInning] =
          rootData.baseBall.awayTeamInningScore[numInning] ?? 0;
      }
      return rootData;
    });

    if (prevInning < numInning) {
      const rootData = result.snapshot.val() as ScoreboardData;
      await makeInningEndLog(matchId, rootData, prevInning);
      await makeInningStartLog(matchId, numInning);
    }
  } catch (error) {
    captureMessage(`updateBaseBallInning 에러 : ${error}`);
    throw error;
  }
};

export const updateScoreInningPoint = async (
  matchId: string,
  point: number,
  teamType: TeamType,
) => {
  const rootRef = getRef(`scoreboard/${matchId}`);
  try {
    const result = await runTransaction(rootRef, (rootData: ScoreboardData) => {
      const totalScore = Number(rootData.common[`${teamType}TeamScore`]);
      if (rootData.baseBall) {
        const inning = Number(rootData.baseBall.inning);
        if (rootData.baseBall[`${teamType}TeamInningScore`] === undefined) {
          rootData.baseBall[`${teamType}TeamInningScore`] = new Array(10).fill(
            0,
          );
          rootData.baseBall[`${teamType}TeamInningScore`][inning] = totalScore;
        }

        const calculatedScore =
          rootData.baseBall[`${teamType}TeamInningScore`][inning] + point;

        if (calculatedScore < 0) throw new Error('Inning Score is minus');

        if (calculatedScore < 999 && calculatedScore >= 0) {
          let calculatedTotalScore = totalScore + point;
          calculatedTotalScore =
            calculatedTotalScore > 999
              ? 999
              : calculatedTotalScore < 0
                ? 0
                : calculatedTotalScore;
          rootData.common[`${teamType}TeamScore`] = `${calculatedTotalScore}`;
          rootData.baseBall[`${teamType}TeamInningScore`][inning] =
            calculatedScore;
        }
      }
      return rootData;
    });

    const rootData = result.snapshot.val() as ScoreboardData;
    await makeTeamScoreLog(matchId, rootData, point, teamType);
  } catch (error) {
    captureMessage(`updateScoreInningPoint 에러 : ${error}`);
    throw error;
  }
};

export const updatePosition = async (
  matchId: string,
  player: MatchPlayer,
  position: PositionUnionType,
) => {
  const matchDataRef = getRef(`scoreboard/${matchId}/matchData`);
  const { id, teamType } = player;
  try {
    await runTransaction(matchDataRef, (matchData: MatchData) => {
      if (matchData) {
        if (teamType == 'home') {
          if (matchData.homePlayers) {
            matchData.homePlayers[
              matchData.homePlayers.findIndex(
                (teamPlayer) => teamPlayer.id === id,
              )
            ].position = position;
          }
        } else {
          if (matchData.awayPlayers) {
            matchData.awayPlayers[
              matchData.awayPlayers.findIndex(
                (teamPlayer) => teamPlayer.id === id,
              )
            ].position = position;
          }
        }
      }
      return matchData;
    });
  } catch (error) {
    captureMessage(`updatePosition 에러 : ${error}`);
    throw error;
  }
};

export const updatePitchCount = async (
  matchId: string,
  player: MatchPlayer,
) => {
  const matchDataRef = getRef(`scoreboard/${matchId}/matchData`);
  const { id, teamType } = player;
  try {
    await runTransaction(matchDataRef, (matchData: MatchData) => {
      if (matchData) {
        const teamPlayers = [...(matchData[`${teamType}Players`] || [])];

        const pitcherIndex = teamPlayers.findIndex(
          (teamPlayer) => teamPlayer.id === id,
        );

        if (pitcherIndex === -1) return matchData;
        teamPlayers[pitcherIndex] = player;

        matchData[`${teamType}Players`] = teamPlayers;
      }
      return matchData;
    });
  } catch (error) {
    captureMessage(`updatePitchCount 에러 : ${error}`);
    throw error;
  }
};

export const updatePlayerStat = async (
  matchId: string,
  player: MatchPlayer,
) => {
  const matchDataRef = getRef(`scoreboard/${matchId}/matchData`);
  const { id, teamType } = player;
  try {
    await runTransaction(matchDataRef, (matchData: MatchData) => {
      if (teamType === 'home') {
        const tmpPlayers = [...(matchData.homePlayers || [])];
        if (tmpPlayers.length === 0) return matchData;

        const targetPlayerIdx = tmpPlayers.findIndex(
          (tmpPlayer) => tmpPlayer.id === id,
        );
        tmpPlayers[targetPlayerIdx] = player;

        matchData.homePlayers = tmpPlayers;
      } else {
        const tmpPlayers = [...(matchData.awayPlayers || [])];
        if (tmpPlayers.length === 0) return matchData;

        const targetPlayerIdx = tmpPlayers.findIndex(
          (tmpPlayer) => tmpPlayer.id === id,
        );
        tmpPlayers[targetPlayerIdx] = player;

        matchData.awayPlayers = tmpPlayers;
      }

      return matchData;
    });
  } catch (error) {
    captureMessage(`updatePlayerStat 에러 : ${error}`);
    throw error;
  }
};

export const updateCurrentHitterId = async (
  matchId: string,
  player: MatchPlayer,
) => {
  const baseBallRef = getRef(`scoreboard/${matchId}/baseBall`);

  const teamType = player.teamType;
  try {
    await runTransaction(baseBallRef, (baseBallData: BaseBall) => {
      if (baseBallData) {
        baseBallData[`${teamType}CurrentHitterId`] =
          baseBallData[`${teamType}CurrentHitterId`] === player.id
            ? -1
            : Number(player.id);
      }
      return baseBallData;
    });
  } catch (error) {
    captureMessage(`updateCurrentHitterId 에러 : ${error}`);
    throw error;
  }
};

export const updateLiveStat = async (
  matchId: string,
  liveStat: BaseballStatsUnion,
) => {
  const matchDataRef = getRef(`scoreboard/${matchId}/matchData`);
  try {
    await runTransaction(matchDataRef, (matchData: MatchData) => {
      if (!matchData) throw new Error('matchData is null');
      matchData.liveStat = liveStat;
      return matchData;
    });
  } catch (error) {
    captureMessage(`updateLiveStat 에러 : ${error}`);
    throw error;
  }
};

export const updateIsIncludePitcherInBatterLineup = async (matchId: string) => {
  const baseBallRef = getRef(`scoreboard/${matchId}/baseBall`);

  try {
    await runTransaction(baseBallRef, (baseBallData: BaseBall) => {
      if (baseBallData) {
        baseBallData.isIncludePitcherInBatterLineup =
          !baseBallData.isIncludePitcherInBatterLineup;
      }
      return baseBallData;
    });
  } catch (error) {
    captureMessage(`updateIsIncludePitcherInBatterLineup 에러 : ${error}`);
    throw error;
  }
};

export const changeFirstAttackTeam = async (matchId: string) => {
  const rootRef = getRef(`scoreboard/${matchId}`);
  try {
    const result = await runTransaction(rootRef, (rootData: ScoreboardData) => {
      if (rootData.baseBall) {
        const homeTeamData = {
          teamName: rootData.common.homeTeamName,
          teamAvatar: rootData.common.homeTeamAvatar,
          teamScore: rootData.common.homeTeamScore,
          teamColor: rootData.common.homeTeamColor,
          teamFontSize: rootData.common.homeTeamFontSize,
          teamInningScore: [...rootData.baseBall.homeTeamInningScore],
          currentHitterId: rootData.baseBall.homeCurrentHitterId ?? -1,
          players:
            rootData.matchData.homePlayers === ''
              ? ('' as const)
              : [...rootData.matchData.homePlayers],
        };
        const awayTeamData = {
          teamName: rootData.common.awayTeamName,
          teamAvatar: rootData.common.awayTeamAvatar,
          teamScore: rootData.common.awayTeamScore,
          teamColor: rootData.common.awayTeamColor,
          teamFontSize: rootData.common.awayTeamFontSize,
          teamInningScore: [...rootData.baseBall.awayTeamInningScore],
          currentHitterId: rootData.baseBall.awayCurrentHitterId ?? -1,
          players:
            rootData.matchData.awayPlayers === ''
              ? ('' as const)
              : [...rootData.matchData.awayPlayers],
        };
        rootData.common.homeTeamName = awayTeamData.teamName;
        rootData.common.homeTeamAvatar = awayTeamData.teamAvatar;
        rootData.common.homeTeamScore = awayTeamData.teamScore;
        rootData.common.homeTeamColor = awayTeamData.teamColor;
        rootData.common.homeTeamFontSize = awayTeamData.teamFontSize;
        rootData.baseBall.homeTeamInningScore = awayTeamData.teamInningScore;
        rootData.baseBall.homeCurrentHitterId = awayTeamData.currentHitterId;

        rootData.common.awayTeamName = homeTeamData.teamName;
        rootData.common.awayTeamAvatar = homeTeamData.teamAvatar;
        rootData.common.awayTeamScore = homeTeamData.teamScore;
        rootData.common.awayTeamColor = homeTeamData.teamColor;
        rootData.common.awayTeamFontSize = homeTeamData.teamFontSize;
        rootData.baseBall.awayTeamInningScore = homeTeamData.teamInningScore;
        rootData.baseBall.awayCurrentHitterId = homeTeamData.currentHitterId;

        if (homeTeamData.players === '') {
          rootData.matchData.awayPlayers = '';
        } else {
          rootData.matchData.awayPlayers = homeTeamData.players.map(
            (player) => ({
              ...player,
              teamType: 'away',
            }),
          );
        }

        if (awayTeamData.players === '') {
          rootData.matchData.homePlayers = '';
        } else {
          rootData.matchData.homePlayers = awayTeamData.players.map(
            (player) => ({
              ...player,
              teamType: 'home',
            }),
          );
        }
      }

      return rootData;
    });

    return result.snapshot.val() as ScoreboardData;
  } catch (error) {
    captureMessage(`changeFirstAttackTeam 에러 : ${error}`);
    throw error;
  }
};

export const clearBallStrike = async (
  matchId: string,
  logType: BaseballUnionLogType,
) => {
  const baseBallRef = getRef(`scoreboard/${matchId}/baseBall`);
  const isOutAddLogType = ['flyBall', 'groundBall', 'strikeOut'].includes(
    logType,
  );
  try {
    await runTransaction(baseBallRef, (baseBallData: BaseBall) => {
      return {
        ...baseBallData,
        ball: '0',
        strike: '0',
        out:
          isOutAddLogType && Number(baseBallData.out) < 2
            ? `${Number(baseBallData.out) + 1}`
            : baseBallData.out,
      };
    });
  } catch (error) {
    captureMessage(`clearBallStrike 에러 : ${error}`);
    throw error;
  }
};

export const getScoreDiff = (
  RDBData: ScoreboardData,
  logType: BaseballUnionLogType,
  initDiff: number | undefined,
) => {
  const isHomeRunLog = logType === 'homeRun';
  let homeRunPoint = 0;

  if (isHomeRunLog && RDBData.baseBall) {
    homeRunPoint = homeRunPoint + 1;
    if (RDBData.baseBall.base1) homeRunPoint = homeRunPoint + 1;
    if (RDBData.baseBall.base2) homeRunPoint = homeRunPoint + 1;
    if (RDBData.baseBall.base3) homeRunPoint = homeRunPoint + 1;
  }

  return isHomeRunLog ? homeRunPoint : initDiff !== undefined ? initDiff : null;
};
