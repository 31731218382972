export const convertToHotkeySymbol = (key: string) => {
  switch (key) {
    case 'ArrowRight':
      key = '→';
      break;
    case 'ArrowLeft':
      key = '←';
      break;
    case 'ArrowUp':
      key = '↑';
      break;
    case 'ArrowDown':
      key = '↓';
      break;
    case ' ':
      key = '␣';
      break;
    case 'Backspace':
      key = '⌫';
      break;
    default:
      break;
  }

  return key;
};
