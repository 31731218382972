import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

const NewIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.5505 23.6414C18.6195 23.6414 22 20.2222 22 15.0776C22 6.55968 14.7181 3 9.69722 3C8.73198 3 8.10231 3.36175 8.10231 4.04606C8.10231 4.3121 8.21951 4.59095 8.43259 4.83739C9.59605 6.23447 10.6696 7.75874 10.6851 9.57052C10.6851 9.9317 10.6464 10.2509 10.3959 10.6987L10.8313 10.6011C10.3327 9.14 9.26746 8.27556 8.31634 8.27556C7.87414 8.27556 7.57306 8.58701 7.57306 9.07537C7.57306 9.36609 7.6407 9.88818 7.6407 10.3485C7.6407 12.5044 6 13.6199 6 17.0936C6 21.0247 9.0097 23.6414 13.5505 23.6414ZM13.6497 22.0579C10.023 22.0579 7.59283 20.0603 7.59283 17.0936C7.59283 14.1207 9.20772 13.1665 9.20433 10.4951C9.20433 10.1599 9.13574 9.85558 9.06622 9.58992L8.77155 9.89553C9.49826 10.4453 9.9919 11.355 10.2679 12.588C10.3124 12.8383 10.4594 12.9733 10.66 12.9733C11.5197 12.9733 12.1 11.0187 12.1 9.66623C12.1 7.54188 11.1945 5.48987 9.7202 4.09637L9.36919 4.50466C16.0256 4.69344 20.349 9.09044 20.349 15.0272C20.349 19.2312 17.6644 22.0579 13.6497 22.0579ZM13.7807 20.7416C15.9566 20.7416 17.0715 19.1607 17.0715 17.2971C17.0715 15.4296 15.9925 13.4268 13.9717 12.4971C13.8581 12.457 13.766 12.5211 13.7856 12.6431C13.9542 14.1033 13.7696 15.4539 13.2872 16.1762C13.0653 15.6146 12.7911 15.1504 12.3781 14.7726C12.2834 14.689 12.1912 14.7393 12.1741 14.8478C12.0284 15.9666 10.8831 16.5143 10.8831 18.1367C10.8831 19.6956 12.0377 20.7416 13.7807 20.7416Z"
      fill="#A3A3A3"
    />
  </SvgIcon>
);

export default NewIcon;
