import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

const AddImage = ({ htmlColor = '#6E717E', ...props }: SvgIconProps) => (
  <SvgIcon viewBox="0 0 24 24" htmlColor={htmlColor} {...props}>
    <path
      d="M13 3H6.6C4.61177 3 3 4.61177 3 6.6V17.4C3 19.3882 4.61177 21 6.6 21H17.4C19.3882 21 21 19.3882 21 17.4V12"
      stroke={htmlColor}
      strokeWidth="2"
      strokeLinecap="round"
      fill="none"
    />
    <path d="M19.5 3C19.5 2.44772 19.0523 2 18.5 2C17.9477 2 17.5 2.44772 17.5 3V4.5H16C15.4477 4.5 15 4.94772 15 5.5C15 6.05228 15.4477 6.5 16 6.5H17.5V8C17.5 8.55228 17.9477 9 18.5 9C19.0523 9 19.5 8.55228 19.5 8V6.5H21C21.5523 6.5 22 6.05228 22 5.5C22 4.94772 21.5523 4.5 21 4.5H19.5V3Z" />
    <path d="M15.0575 12.0491C14.6972 11.5837 13.9945 11.5837 13.6342 12.0491L11.0809 15.3471L9.58539 13.6202C9.22649 13.2058 8.58361 13.2058 8.22472 13.6202L6.10276 16.0704C5.59797 16.6533 6.01201 17.5596 6.78309 17.5596H11.027C11.0615 17.5596 11.0954 17.5578 11.1284 17.5543C11.1535 17.5563 11.1789 17.5573 11.2048 17.5573H17.4869C18.2349 17.5573 18.6565 16.6978 18.1986 16.1064L15.0575 12.0491Z" />
  </SvgIcon>
);

export default AddImage;
