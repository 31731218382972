import type { ReactNode } from 'react';

import type { SwipeableDrawerProps } from '@mui/material';
import { Box, SwipeableDrawer } from '@mui/material';

const BottomSheet = ({
  children,
  ...props
}: {
  children: ReactNode;
} & SwipeableDrawerProps) => (
  <SwipeableDrawer
    anchor="bottom"
    disableSwipeToOpen={true}
    PaperProps={{
      sx: {
        display: 'flex',
        p: '12px 24px 24px',
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
      },
    }}
    {...props}
  >
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          width: '64px',
          height: '6px',
          bgcolor: 'gray.100',
          borderRadius: '30px',
          mb: '12px',
        }}
      />
    </Box>
    {children}
  </SwipeableDrawer>
);

export default BottomSheet;
