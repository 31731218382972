import { useMediaQuery, useTheme } from '@mui/material';

const useCheckDevice = () => {
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('sm'));
  const isTablet = useMediaQuery(breakpoints.between('sm', 'lg'));
  const isPc = useMediaQuery(breakpoints.up('lg'));
  const isNotPc = useMediaQuery(breakpoints.down('lg'));

  const isSmToMd = useMediaQuery(breakpoints.between('sm', 'md'));
  const isMdToLg = useMediaQuery(breakpoints.between('md', 'lg'));

  return { isMobile, isTablet, isPc, isNotPc, isSmToMd, isMdToLg };
};

export default useCheckDevice;
