import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

const Zoom = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 32 32" htmlColor="#2D8CFF" {...props}>
    <path d="m6.115 18.172l-.36-.016H2.522l4.312-4.312l-.016-.36a1.096 1.096 0 0 0-1.063-1.063l-.359-.015H.005l.021.359c.041.584.468 1.027 1.056 1.063l.36.016h3.24L.363 18.156l.016.36a1.105 1.105 0 0 0 1.063 1.063l.36.015h5.391l-.016-.359c-.047-.589-.469-1.021-1.063-1.057zm4.317-5.765h-.005c-4.791.005-4.787 7.187 0 7.187c4.792 0 4.792-7.181.005-7.187zm1.521 5.12a2.155 2.155 0 0 1-3.089.036a2.16 2.16 0 0 1 .037-3.089c2.036-1.932 4.984 1.016 3.052 3.053zm17.162-5.12c-.823 0-1.604.353-2.151.973a2.878 2.878 0 0 0-3.77-.478c-.276-.313-.907-.495-1.261-.495v7.187l.36-.015c.599-.043 1.036-.464 1.057-1.063l.02-.36V15.64l.016-.359c.016-.271.052-.511.177-.719a1.438 1.438 0 0 1 1.964-.527c.219.125.395.308.52.521s.161.453.177.724l.021.359v2.516l.015.36c.037.583.469 1.02 1.063 1.063l.36.015V15.64l.015-.359c.011-.265.052-.516.177-.724a1.435 1.435 0 0 1 2.489.006c.12.208.157.453.172.719l.021.359v2.516l.016.36a1.108 1.108 0 0 0 1.063 1.063l.359.015v-4.312a2.879 2.879 0 0 0-2.88-2.875zm-13.678 1.052a3.591 3.591 0 0 0-.068 5.145a3.592 3.592 0 0 0 5.147-.063c3.224-3.385-1.693-8.301-5.079-5.083zm4.063 4.068a2.157 2.157 0 1 1-3.047-3.053c2.031-1.979 5.031 1.016 3.047 3.053z" />
  </SvgIcon>
);

export default Zoom;
