import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

const Warning = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path
      d="M1.72505 18.5C1.34172 18.5 1.05838 18.3333 0.875049 18C0.691715 17.6667 0.691715 17.3333 0.875049 17L10.125 1C10.3084 0.666667 10.6 0.5 11 0.5C11.4 0.5 11.6917 0.666667 11.875 1L21.125 17C21.3084 17.3333 21.3084 17.6667 21.125 18C20.9417 18.3333 20.6584 18.5 20.275 18.5H1.72505ZM11 7.5C10.7167 7.5 10.4794 7.59567 10.288 7.787C10.096 7.979 10 8.21667 10 8.5V11.5C10 11.7833 10.096 12.0207 10.288 12.212C10.4794 12.404 10.7167 12.5 11 12.5C11.2834 12.5 11.521 12.404 11.713 12.212C11.9044 12.0207 12 11.7833 12 11.5V8.5C12 8.21667 11.9044 7.979 11.713 7.787C11.521 7.59567 11.2834 7.5 11 7.5ZM11 15.5C11.2834 15.5 11.521 15.404 11.713 15.212C11.9044 15.0207 12 14.7833 12 14.5C12 14.2167 11.9044 13.9793 11.713 13.788C11.521 13.596 11.2834 13.5 11 13.5C10.7167 13.5 10.4794 13.596 10.288 13.788C10.096 13.9793 10 14.2167 10 14.5C10 14.7833 10.096 15.0207 10.288 15.212C10.4794 15.404 10.7167 15.5 11 15.5ZM3.45005 16.5H18.55L11 3.5L3.45005 16.5Z"
      fill="#FFCA0D"
    />
  </SvgIcon>
);

export default Warning;
