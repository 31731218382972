import { useEffect, useState } from 'react';

import { useRouter } from 'next/router';

import type { SxProps } from '@mui/material';
import { Box } from '@mui/material';

import useActiveAuth from 'hooks/useActiveAuth';
import useCheckDevice from 'hooks/useCheckDevice';

import type { AdIdType } from 'types/auth';

import CustomAdCard from './CustomAdCard';

// 4번째 이미지는 PC사이즈에서 유튜브 로고 사용으로 인해 임시적으로 제외 -> 추후 수정시 다시 추가
const KO_DEFAULT_BANNER_IMG_NUM = 3;
const EN_DEFAULT_BANNER_IMG_NUM = 3;

const ResponsiveBannerAdCard = ({
  type = 'large',
  size,
  adId,
  sx,
}: {
  type?: 'small' | 'large';
  size?: 'xsmall' | 'mobile' | 'tablet' | 'pc';
  adId: AdIdType;
  sx?: SxProps;
}) => {
  const { isProfileLoadProcessComplete, isSubscribedUser } = useActiveAuth();
  const { isMobile, isTablet, isPc } = useCheckDevice();
  const { locale } = useRouter();

  const [adSize, setAdSize] = useState<'xsmall' | 'mobile' | 'tablet' | 'pc'>(
    size || 'mobile',
  );
  const [defaultImageNum, setDefaultImageNum] = useState(0);

  const isAndroid =
    typeof navigator !== 'undefined' && navigator.userAgent.match(/Android/i);

  const cardSize = {
    xsmall: {
      width: '320px',
      height: '50px',
    },
    mobile: {
      width: '320px',
      height: '100px',
    },
    tablet: {
      width: '600px',
      height: '90px',
    },
    pc: {
      width: '1200px',
      height: '90px',
    },
  };

  const bannerLink: {
    ko: { [key: number]: string };
    en: { [key: number]: string };
  } = {
    ko: {
      0: 'https://cafe.naver.com/camerafilive/4384',
      1: 'https://www.facebook.com/camerafikor?locale=ko_KR',
      2: 'https://www.instagram.com/camerafi_/',
      3: isAndroid
        ? 'https://play.google.com/store/apps/details?id=com.vaultmicro.camerafi.live&pcampaignid=web_share'
        : 'https://apps.apple.com/us/app/camerafi-live/id6448773005',
    },
    en: {
      0: 'https://blog.camerafi.com/p/tutorials-how-to-use-camerafi-studio.html#',
      1: 'https://www.facebook.com/groups/170216666130703',
      2: 'https://www.instagram.com/camerafi_/',
      3: isAndroid
        ? 'https://play.google.com/store/apps/details?id=com.vaultmicro.camerafi.live&pcampaignid=web_share'
        : 'https://apps.apple.com/us/app/camerafi-live/id6448773005',
      4: 'https://www.youtube.com/@CameraFi',
    },
  };

  const handleClickDefaultAd = () => {
    window.open(
      bannerLink[locale === 'ko' ? 'ko' : 'en'][defaultImageNum],
      '_blank',
    );
  };

  useEffect(() => {
    const getRandomNumForDefaultImg = () => {
      const max =
        locale === 'ko' ? KO_DEFAULT_BANNER_IMG_NUM : EN_DEFAULT_BANNER_IMG_NUM;

      return Math.floor(Math.random() * max);
    };

    const randomNum = getRandomNumForDefaultImg();

    setDefaultImageNum(randomNum);
  }, [locale]);

  useEffect(() => {
    if (size === undefined) {
      if (isMobile) {
        if (type === 'small') setAdSize('xsmall');
        else setAdSize('mobile');
      } else if (isTablet) setAdSize('tablet');
      else if (isPc) setAdSize('pc');
    }
  }, [isMobile, isTablet, isPc, size, type]);

  return isProfileLoadProcessComplete && !isSubscribedUser ? (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        my: 0.5,
        ...sx,
      }}
    >
      {adSize === 'xsmall' ? (
        <CustomAdCard
          adId={adId}
          width={cardSize.xsmall.width}
          height={cardSize.xsmall.height}
          defaultImageURL={`/img/ad_googleDefault/bannerMobile${defaultImageNum}_${
            locale || 'en'
          }.webp`}
          onClick={handleClickDefaultAd}
        />
      ) : null}

      {adSize === 'mobile' ? (
        <CustomAdCard
          adId={adId}
          width={cardSize.mobile.width}
          height={cardSize.mobile.height}
          defaultImageURL={`/img/ad_googleDefault/bannerMobile${defaultImageNum}_${
            locale || 'en'
          }.webp`}
          onClick={handleClickDefaultAd}
        />
      ) : null}

      {adSize === 'tablet' ? (
        <CustomAdCard
          adId={adId}
          width={cardSize.tablet.width}
          height={cardSize.tablet.height}
          defaultImageURL={`/img/ad_googleDefault/bannerTablet${defaultImageNum}_${
            locale || 'en'
          }.webp`}
          onClick={handleClickDefaultAd}
        />
      ) : null}

      {adSize === 'pc' ? (
        <CustomAdCard
          adId={adId}
          width={cardSize.pc.width}
          height={cardSize.pc.height}
          defaultImageURL={`/img/ad_googleDefault/bannerPC${defaultImageNum}_${
            locale || 'en'
          }.webp`}
          onClick={handleClickDefaultAd}
        />
      ) : null}
    </Box>
  ) : null;
};

export default ResponsiveBannerAdCard;
