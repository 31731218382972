import { useContext } from 'react';

import { HotkeyContext } from 'contexts/HotkeyContext';

const useHotkey = () => {
  const context = useContext(HotkeyContext);

  if (!context)
    throw new Error('HotkeyContext must be placed within HotkeyProvider');

  return context;
};

export default useHotkey;
