import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

const ForYouIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask
      id="mask0_171_74180"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="28"
      height="28"
    >
      <rect width="28" height="28" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_171_74180)">
      <path
        d="M22.1641 10.4993L20.7057 7.29102L17.4974 5.83268L20.7057 4.37435L22.1641 1.16602L23.6224 4.37435L26.8307 5.83268L23.6224 7.29102L22.1641 10.4993ZM22.1641 26.8327L20.7057 23.6243L17.4974 22.166L20.7057 20.7077L22.1641 17.4993L23.6224 20.7077L26.8307 22.166L23.6224 23.6243L22.1641 26.8327ZM10.4974 23.3327L7.58073 16.916L1.16406 13.9993L7.58073 11.0827L10.4974 4.66602L13.4141 11.0827L19.8307 13.9993L13.4141 16.916L10.4974 23.3327ZM10.4974 17.6743L11.6641 15.166L14.1724 13.9993L11.6641 12.8327L10.4974 10.3244L9.33073 12.8327L6.8224 13.9993L9.33073 15.166L10.4974 17.6743Z"
        fill="#A3A3A3"
      />
    </g>
  </SvgIcon>
);

export default ForYouIcon;
