import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

const Lock = ({ sx, ...rest }: SvgIconProps) => (
  <SvgIcon viewBox="0 0 24 24" sx={{ fill: 'none', ...sx }} {...rest}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 7.72656C4.23858 7.72656 2 9.96514 2 12.7266V17.2266C2 19.988 4.23858 22.2266 7 22.2266H17C19.7614 22.2266 22 19.988 22 17.2266V12.7266C22 9.96514 19.7614 7.72656 17 7.72656H7ZM4 12.7266C4 11.0697 5.34315 9.72656 7 9.72656H17C18.6569 9.72656 20 11.0697 20 12.7266V17.2266C20 18.8834 18.6569 20.2266 17 20.2266H7C5.34315 20.2266 4 18.8834 4 17.2266V12.7266ZM12.0017 13.2274C11.5183 13.2274 11.1265 13.6193 11.1265 14.1027V15.8531C11.1265 16.3365 11.5183 16.7283 12.0017 16.7283C12.4851 16.7283 12.8769 16.3365 12.8769 15.8531V14.1027C12.8769 13.6193 12.4851 13.2274 12.0017 13.2274Z"
      fill="currentColor"
    />
    <path
      d="M8 8.5L8 7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7V8.5"
      stroke="currentColor"
      strokeWidth="2"
    />
  </SvgIcon>
);

export default Lock;
