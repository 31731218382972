let timerId: NodeJS.Timeout | undefined;

const debouncing = (callBack: () => void, delay: number) => {
  if (timerId) clearTimeout(timerId);

  timerId = setTimeout(() => {
    callBack();
  }, delay);
};

export default debouncing;
