import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

const Youtube = (props: SvgIconProps) => (
  <SvgIcon htmlColor="#FF0000" viewBox="0 0 24 24" {...props}>
    <path
      d="M22.54 7.6s-.2-1.5-.86-2.17c-.83-.87-1.75-.88-2.18-.93-3.04-.22-7.6-.2-7.6-.2s-4.56-.02-7.6.2c-.43.05-1.35.06-2.18.93-.65.67-.86 2.18-.86 2.18S1.04 9.4 1 11.18v1.66c.04 1.78.26 3.55.26 3.55s.2 1.5.86 2.18c.83.87 1.9.84 2.4.94 1.7.15 7.2.2 7.38.2 0 0 4.57 0 7.6-.22.43-.05 1.35-.06 2.18-.93.65-.67.86-2.18.86-2.18s.22-1.77.24-3.55v-1.66c-.02-1.78-.24-3.55-.24-3.55z"
      fill="currentColor"
    />
    <path d="M9.68 8.9v6.18l5.84-3.1" fill="#FAFAFA" />
  </SvgIcon>
);

export default Youtube;
