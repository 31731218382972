import useTranslation from 'next-translate/useTranslation';
import NextHead from 'next/head';
import { useRouter } from 'next/router';

import { useTheme } from '@mui/material';

interface MetaProps {
  date?: string;
  description?: string;
  image?: string;
  title?: string;
  type?: string;
  url?: string;
  keyword?: string;
}

const Head = ({ customMeta }: { customMeta?: MetaProps }) => {
  const router = useRouter();
  const theme = useTheme();
  const { t } = useTranslation('common');

  const meta: MetaProps = {
    description: customMeta?.description || t('meta.description'),
    url: 'https://studio.camerafi.com',
    ...customMeta,
    title: customMeta?.title
      ? t('meta.customTitle', { customTitle: customMeta.title })
      : t('meta.title'),
    image: customMeta?.image
      ? `${customMeta.image}`
      : 'https://firebasestorage.googleapis.com/v0/b/camerafi-live-1139.appspot.com/o/CF_Studio_meta.webp?alt=media&token=0d5b475a-4cf9-47fd-a1ea-fc7fa8607a33',
    keyword: customMeta?.keyword ? `${customMeta.keyword}` : t('meta.keyword'),
  };

  return (
    <NextHead>
      <title>{meta.title}</title>
      <meta name="title" content={meta.title} key="title" />
      <meta name="description" content={meta.description} key="description" />
      <meta name="keyword" content={meta.keyword} key="keyword" />
      <meta
        name="theme-color"
        content={theme.palette.background.default}
        key="themeColor"
      />
      <link
        rel="canonical"
        href={`${meta.url}/${router.locale}${router.asPath}`}
      />

      <meta property="og:title" content={meta.title} key="og_title" />
      <meta
        property="og:description"
        content={meta.description}
        key="og_description"
      />
      <meta
        property="og:url"
        content={`${meta.url}${router.asPath}`}
        key="og_url"
      />
      <meta property="og:site_name" content={meta.title} key="og_site_name" />
      <meta property="og:image" content={meta.image} key="og_image" />

      <meta name="twitter:title" content={meta.title} key="twt_title" />
      <meta
        name="twitter:description"
        content={meta.description}
        key="twt_description"
      />
      <meta name="twitter:image" content={meta.image} key="twt_image" />
      <meta name="twitter:url" content={meta.url} key="twt_url" />

      <meta
        name="apple-mobile-web-app-title"
        content={meta.title}
        key="apple_mobile_title"
      />

      <meta
        name="application-name"
        content={meta.title}
        key="application_name"
      />
    </NextHead>
  );
};

export default Head;
